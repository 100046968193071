.navbar-dark .navbar-toggler {
  border: none;
  outline: none;
  background: none;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler img {
  width: 30px;
  height: auto;
  margin-bottom: 5px;
}

.navbar {
  background-color: var(--background);
  color: var(--font-color);
}

.brand-name {
  color: var(--purple);
  font-family: 'Kaushan Script', cursive;
}

.brand-name,
.text-muted {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.navbar-brand-link {
  text-decoration: none;
  color: inherit;
}
.navbar-brand-link {
  text-decoration: none;
  color: inherit;
}

.navbar-brand-link:hover,
.navbar-brand-link:focus {
  text-decoration: none;
  outline: none;
}

.nav-list li {
  /* margin-left: 10px; */
}

.nav-list li:last-child {
  margin-right: 0;
}
.nav-list .nav-item .nav-link {
  color: var(--purple) !important;
  font-weight: bolder;
  position: relative;
  transition: color 0.3s ease;
}

.nav-list .nav-item .nav-link:hover {
  color: var(--orange) !important;
}

.nav-list .nav-item .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--orange) !important;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.nav-list .nav-item .nav-link:hover::after {
  visibility: visible;
  width: 100%;
}

.nav-list .nav-item .nav-link:active {
  color: var(--orange) !important;
}

.active-link {
  color: var(--orange) !important;
}

.navbar-brand-link {
  text-decoration: none;
  color: inherit;
}

.dropdown-toggle {
  color: var(--purple) !important;
  background-color: transparent !important;
  border: none !important;
  font-weight: bolder !important;
  margin-top: 2px;
  /* margin-right: 4px; */

}

.dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease-in-out;
}

/* Remove or override styles on active */
.dropdown-toggle::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0.5px;
  bottom: -1px;
  left: 0;
  /* background-color: var(--orange) !important; */
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.dropdown-toggle:hover {
  color: var(--orange) !important;
}
.dropdown-toggle:hover::after {
  visibility: visible;
  width: 100%;
  color: var(--orange) !important;
}

.dropdown-menu {
  background-color: var(--background) !important;
  /* border: 1px solid var(--purple) !important; */
  box-shadow: var(--shadow);
}

.dropdown-menu .dropdown-item {
  color: var(--purple) !important;
  font-weight: bolder;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: var(--orange) !important;
}

.dropdown-toggle {
  color: var(--purple) !important; 
  transition: color 0.3s ease;
}

.dropdown-toggle:hover {
  color: var(--orange) !important; 
}

@media (max-width: 992px) {
  .navbar-collapse {
    width: 100%;
    flex-grow: 1;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-left: 0;
  }

  .brand-name {
    font-size: 32px;
  }

  .dropdown-toggle{
   padding-right: 0 !important;
   margin-right: 0 !important;
  }
}
