.home {
  padding-top: 10vh; 
  padding-bottom: 10vh; 
}
.text-block {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  height: 80vh; /* Adjust based on your design */
}

.navbar-headshot {
  width: 275px;
  height: 300px;
  margin-right: 10px;
}



.wave {
  display: inline-block;
  animation: wave-stop 0s;
  animation-fill-mode: forwards;
}

.wave:hover {
  animation: wave 1.5s;
  animation-fill-mode: forwards;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(14deg);
  }
  60% {
    transform: rotate(-8deg);
  }
  70% {
    transform: rotate(14deg);
  }
  80% {
    transform: rotate(-4deg);
  }
  90% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 767px) {

  .home {

    padding-bottom: 10vh !important; 
  }

  .navbar-headshot {
    width: 150px;
    height: 160px;
    margin-left: 25px;
    margin-bottom: 15px;
  }
  .text-block {
    text-align: left;
    height: auto; 
  }
}
