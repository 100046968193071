contact {
  padding: 35px;
  margin-bottom: auto;
  background-color: var(--background);
  text-align: center;
  
}

contact div a {
  margin: 0 10px;
}

contact div a img {
  height: 50px;
  width: 50px;
}
.contact {
  padding-top: 10vh; 
  padding-bottom: 20vh; 
}
.contact-box {
  padding: 35px;
  margin: auto;
  margin-top: 20px; 
  text-align: center;
  /* border: 2px solid var(--purple);  */
  border-radius: 10px; 
  max-width: 700px; 
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  gap: 35px; 
}

.contact-box a img {
  height: 50px;
  width: 50px;
}


@media (max-width: 768px) {
  .contact-box {
    gap: 25px;
  }

  .contact-box a img {
    height: 45px;
    width: 45px;
  }
}