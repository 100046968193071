@import url("https://fonts.googleapis.com/css2?family=Gudea:ital@1&family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

:root {
  /* --purple: #55198b; */
  --appleWhite: #f5f5f7;
  --appleBlack: #1d1d1f;
  --orange: #d2742d;
  --grey: #a1a1a1;
  --shadow-light: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* shadow for light theme */
  --shadow-dark: 0 0.5rem 1rem rgba(255, 255, 255, 0.15);
}

.light {
  --background: var(--appleWhite);
  --font-color: var(--appleBlack);
  --shadow: var(--shadow-light);
  --purple: #7c4da4;
}

.dark {
  --background: var(--appleBlack);
  --font-color: var(--appleWhite);
  --shadow: var(--shadow-dark);
  --purple: #9b4ede;
  
}

* {
  background: var(--background);
  color: var(--font-color);
  font-family: "Gudea", sans-serif;
  font-family: "Montserrat", sans-serif;
}
html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  /* width: 100%;
  height: 100%; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Gudea", "Montserrat",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
  color: var(--font-color);
  --shadow: var(--shadow-light);
}
.section-anchor {
  padding-top: 70px; 
  margin-top: -70px; 
}

footer {
  margin-top: auto;
}

body.dark {
  --shadow: var(--shadow-dark);
}

h2 {
  text-align: left;
  padding-bottom: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 767px) {
  .section-anchor {
    padding-top: 300px; /* Slightly more than navbar height */
    margin-top: -300px; /* Equal to padding-top */
  }
}