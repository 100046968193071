h3 {
  padding-left: 16px;
}

.card {
  background-color: var(--background);
}
.card-title {
  color: var(--purple);
}

.card-text {
  color: var(--font-color);
}

.horizontal-card {
  display: flex;
  align-items: flex-start;
  border: none;
  margin-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 2px solid #ddd;
    padding-bottom: 1rem;
  }
}

.horizontal-card .card-img-top {
  width: 100%;
  height: auto;
}

.horizontal-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.project-links {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.btn-proj, .btn-uiux{
  color: var(--purple);
  font-weight: bolder;
  position: relative;
  transition: color 0.3s ease;
  background-color: transparent;
  border: none;
  padding-bottom: 2px; 

  &:hover,
  &:focus {
    color: var(--purple);
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--purple);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover::after {
    visibility: visible;
    width: 100%;
  }

  &:active {
    color: var(--orange) ;
  }

  &:active::after {
    background-color: none;
  }
}

.image-container {
  flex-shrink: 0;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.horizontal-card .image-container .project-img {
  width: 400px !important;
  height: auto !important;
  object-fit: contain !important;
}

.tech-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .horizontal-card, .horizontal-card .d-flex {
    flex-direction: column; 
    align-items: center; 
  }

  .horizontal-card .image-container .project-img {
    width: 325px !important;
    height: auto;
    margin-bottom: 15px;
  }

  .project-technologies {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .image-container {
    justify-content: center;
    margin-right: 0; 
    margin-left: 0;
  }
  
}
