.quote-card {
  max-width: 50%;
  /* margin: 2em auto; */
  padding: 0 1em;
  
}

.quote-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-text {
  margin-bottom: 1em;
  font-size: 20px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;

}

.quote-button {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.5em 1em;
}

.button-text-content {
  display: inline-block;
}

.button-text-name {
  font-size: 20px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
  font-style: normal;
  color: var(--purple);

  &:active {
    color: var(--orange);
  }

  &:active::after {
    background-color: var(--orange);
  }
}

.button-text-role {
  display: block;
  font-size: smaller;
  color: var(--purple);

  &:active {
    color: var(--orange);
  }

  &:active::after {
    background-color: var(--orange);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .quote-card {
    max-width: 95%;
    padding: 0;
  }
  .quote-text {
    font-size: 18px !important;
  }

  .quote-button {
    font-size: 18px !important;
  }
}
