.skills-carousel {
  overflow-x: auto;
  white-space: nowrap;
}

.skill-col {
  display: inline-block;
  margin: 10px;
  vertical-align: top;
}


.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-col {
  margin: 10px;
}

.skill-item {
  text-align: center; 
  padding: 10px;
  transform: translate3d(0, 0, 0);
}

.skills-logo {
  max-width: 60px;
  height: 60px;
  margin: 10px auto; 
  display: block; 
}

.skill-name {
  font-size: 16px;
  color: var(--font-color);
  margin-top: 8px; 
}

.skill-descriptions {
  list-style-type: none;
  padding-left: 0;
  font-size: 18px;
}

.skill-descriptions li {
  margin-bottom: 20px;
  color: var(--font-color);
}

.proficiencies {
  margin-bottom: 10%;
}

.skills-box {
  border: 2px solid var(--purple); 
  border-radius: 10px; 
  padding: 20px;
  margin-top: 20px;

  overflow: hidden; 
}

/* For medium-sized screens */
@media (max-width: 950px) {
  .skills-logo {
    width: 50px;
    height: 50px;
  }

  .skill-name {
    font-size: 14px;
  }

  .proficiencies {
    margin-bottom: 0;
  }
}



